import { render, staticRenderFns } from "./FaceTime.vue?vue&type=template&id=473004fb&scoped=true&"
import script from "./FaceTime.vue?vue&type=script&lang=js&"
export * from "./FaceTime.vue?vue&type=script&lang=js&"
import style0 from "./FaceTime.vue?vue&type=style&index=0&id=473004fb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473004fb",
  null
  
)

export default component.exports