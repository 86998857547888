<template>
	<div class="facetime">
		<audio id="ring" preload>
			<source src="https://baigongbao.oss-cn-beijing.aliyuncs.com/audio/ring.mp3" type="audio/mpeg" />
		</audio>

		<audio id="video_call_ring" preload loop="loop" >
			<source src="https://baigongbao.oss-cn-beijing.aliyuncs.com/audio/video_call.mp3" type="audio/mpeg"/>
		</audio>
<!--		视频通话弹窗-->
		<div id="video_call" class="video_call" v-drag v-show="calling != 0">
<!--			我拨打视频等待对方接听时显示calling==1-->
			<div class="waiting" v-show="calling == 1">
				等待接听中
			</div>
<!--			其他人邀请视频通话时显示-->
			<div class="waiting" v-show="calling == 2">
				{{other_user.nickName}}邀请您通话
			</div>
<!--			正在通话中-->
			<div v-html="remoteStream" :class="remoteStream?'distant-stream':''" v-show="calling == 9">
			</div>
			<div id='local_stream' class="local-stream" v-show="calling == 9">
			</div>

			<!--		     接听通话-->
			<div class="get_video_call op sc hand" @click="confirm_video_call" style="text-align: center;" v-show="calling == 2">
				<i class="el-icon-phone" style="color:#FFF;font-size:30px;height:40px;line-height:40px;margin:8px 0px 0 0; "></i>
			</div>

			<!-- 拒接 -->
			<div class="close_video_call op sc hand" @click="not_confirm_video_call" style="text-align: center;" >
				<i class="el-icon-phone" style="color:#FFF;font-size:30px;height:40px;line-height:40px;margin:8px 0px 0 0; transform:rotate(133deg);
					-ms-transform:rotate(133deg); 	/* IE 9 */
					-moz-transform:rotate(133deg); 	/* Firefox */
					-webkit-transform:rotate(133deg); /* Safari 和 Chrome */
					-o-transform:rotate(133deg);"></i>
			</div>
		</div>
	</div>
</template>

<script>
import TRTC from 'trtc-js-sdk';
import LibGenerateTestUserSig from '../../utils/lib-generate-test-usersig.min'


export default {
	name: "FaceTime",
	data(){
		return {
			drawer: false,
			calling:0,//0未通话 1我拨打视频对方待接通 2邀请我进行视频  9正在通话中
			other_user:'',
			remoteStream: '',//远方播放流
			localStream: '',//本地流
			TRTCclient:"",//客户端服务
			roomId:0,
			// uuid: 'uuid_' + parseInt(Math.random() * 100000000),//用户id --可更改

		}
	},
	computed: {
		user() {
			return this.$store.state.user
		},
		is_login(){
			return this.$store.state.is_login
		},
		router(){
			return this.$store.state.router
		}

	},
	mounted() {

		var that = this;
		that.$EventBus.$on("facetime", ( info ) => {
			that.$nextTick(() => {
				that.calling = 2;
				// that.roomId = parseInt(info.roomId);
				that.createTRTCclient(that.user.uuid,parseInt(info.roomId));
			});
		});
		this.$EventBus.$on('face_time', (ids) => {
		  console.log(111)
			var deviceList = [];
			navigator.mediaDevices.enumerateDevices().then(devices => {
				devices.forEach(device => {
					deviceList.push(device.kind);
				});
				if (deviceList.indexOf("videoinput") == "-1") {
					console.info("没有摄像头");
				} else {
					console.info("有摄像头");
					that.calling = 1;
					this.make_video_call(JSON.stringify(ids))
				}
			}).catch(function (err) {
				alert(err.name + ": " + err.message);
			});

		})

	},
	methods:{
		genTestUserSig(userID) {
			/**
			 * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
			 *
			 * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
			 * 它是腾讯云用于区分客户的唯一标识。
			 */
			const SDKAPPID = 1400629374;

			/**
			 * 签名过期时间，建议不要设置的过短
			 * <p>
			 * 时间单位：秒
			 * 默认时间：7 x 24 x 60 x 60 = 604800 = 7 天
			 */
			const EXPIRETIME = 604800;

			/**
			 * 计算签名用的加密密钥，获取步骤如下：
			 *
			 * step1. 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav )，如果还没有应用就创建一个，
			 * step2. 单击“应用配置”进入基础配置页面，并进一步找到“帐号体系集成”部分。
			 * step3. 点击“查看密钥”按钮，就可以看到计算 UserSig 使用的加密的密钥了，请将其拷贝并复制到如下的变量中
			 *
			 * 注意：该方案仅适用于调试Demo，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。
			 * 文档：https://cloud.tencent.com/document/product/647/17275#Server
			 */
			const SECRETKEY = '4a0b3152bd395ea70744d1fa4ecd343668f26ab33d41ec465d2b928d00c12751';
			const generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
			const userSig = generator.genTestUserSig(userID);
			return {
				sdkAppId: SDKAPPID,
				userSig: userSig
			};
		},
		/**
		 * 创建客户端
		 * @param userId
		 * @param roomId
		 */
		createTRTCclient (userId,roomId) {
			console.log("info:",roomId,userId)
			//获取签名
			this.calling = 9;
			const config = this.genTestUserSig(userId)
			const sdkAppId = config.sdkAppId
			const userSig = config.userSig
			this.TRTCclient = TRTC.createClient({
				mode: 'rtc',
				sdkAppId,
				userId,
				userSig
			});
			//注册远程监听，要放在加入房间前--这里用了发布订阅模式
			this.subscribeStream(this.TRTCclient)
			//初始化后才能加入房间
			this.joinRoom(this.TRTCclient, roomId)
		},
		//订阅远端流--加入房间之前
		subscribeStream (TRTCclient) {
			TRTCclient.on('stream-added', event => {
				const remoteStream = event.stream;
				console.log('远端流增加: ' + remoteStream.getId());
				//订阅远端流
				TRTCclient.subscribe(remoteStream);
			});
		},
		//加入房间
		joinRoom (TRTCclient, roomId) {
			TRTCclient.join({ roomId })
				.catch(error => {
					console.error('进房失败 ' + error);
				}).then(() => {
				//创建本地流
				this.createStream(this.userId)
				//播放远端流
				this.playStream(this.TRTCclient)
			});
		},
		//创建本地音视频流
		createStream (userId) {
			const localStream = TRTC.createStream({ userId, audio: true, video: true });
			this.localStream =localStream
			localStream
				.initialize()
				.catch(error => {
					console.error('初始化本地流失败 ' + error);
				})
				.then(() => {
					// 创建好后才能播放 本地流播放 local_stream 是div的id
					localStream.play('local_stream');
					//创建好后才能发布
					this.publishStream(localStream, this.TRTCclient)
				});
		},
		//发布本地音视频流
		publishStream (localStream, TRTCclient) {
			TRTCclient.publish(localStream).catch(error => {
				console.error('本地流发布失败 ' + error);
			})
				.then(() => {
					console.log('本地流发布成功');
				});
		},
		//播放远端流
		playStream (TRTCclient) {
			TRTCclient.on('stream-subscribed', event => {
				const remoteStream = event.stream;
				console.log('远端流订阅成功：' + remoteStream.getId());
				// 创建远端流标签，因为id是动态的，所以动态创建，用了v-html

				this.remoteStream = `<div id="${'remote_stream-' + remoteStream.getId()}" style="height:100%;"  ></div>`;

				//做了dom操作 需要使用$nextTick(),否则找不到创建的标签无法进行播放
				this.$nextTick(() => {
					//播放
					remoteStream.play('remote_stream-' + remoteStream.getId());
				})
			});
		},
		//退出音视频
		leaveRoom () {
			this.TRTCclient.leave().then(() => {
				console.log('退房成功')
				// 停止本地流，关闭本地流内部的音视频播放器
				this.localStream.stop();
				// 关闭本地流，释放摄像头和麦克风访问权限
				this.localStream.close();
				this.localStream = null;
				this.TRTCclient = null
				// 退房成功，可再次调用client.join重新进房开启新的通话。
			}).catch(error => {
				console.error('退房失败 ' + error);
				// 错误不可恢复，需要刷新页面。
			});
		},
		/**
		 * 我进行拨打视频电话
		 * type  1是双人视频2是群聊
		 * ids 视频的所有人的uuid
		 */
		make_video_call(ids){
			var that = this;
			var obj = {
				ids:ids,
				type:1
			}
			that.newApi.makeFaceTime(obj).then(res=>{
				that.calling = 1;
				that.video_call_ring();//播放声音
				// console.log(res)
				that.createTRTCclient(that.user.uuid,parseInt(res.data));
			});


			// 提醒通话时间
			// that.setTimeout(function(){
			//     that.reduce_video_call_surplus_minute();
			// },1000)
		},

		/**
		 * 同意接听视频
		 */
		confirm_video_call(){
			var that = this;
			// that.video_call_stop();
			that.calling = 9;
			that.createTRTCclient(that.user.uuid+'',parseInt(that.roomId));
			// var obj = {
			// 	content:{
			// 		content:"接听了语音"
			// 	},
			// }
			// this.send_msg(obj,998);
		},
		//B->A拒绝接听
		not_confirm_video_call(){
			var that = this;
			that.video_call_stop();
			// var obj = {
			// 	content:{
			// 		content:"拒绝接听"
			// 	},
			// }
			// this.send_msg(obj,997);
			this.calling = 0;
			this.leaveRoom();
		},

		video_call_ring:function(){
			console.log('播放音乐！');
			console.log(document.getElementById("video_call_ring").play());
		},
		video_call_stop:function(){
			console.log('停止音乐！');
			console.log(document.getElementById("video_call_ring"));
			document.getElementById("video_call_ring").load();
		},


	},
	directives:{
		/**
		 * 通话视频页面移动
		 * @param el
		 */
		drag(el){
			var video_call = document.getElementById("video_call");
			el.onmousedown = function(e){
				var disx = e.pageX - video_call.offsetLeft;
				var disy = e.pageY - video_call.offsetTop;
				document.onmousemove = function (e){
					if ( (document.documentElement.clientWidth - 334 - (e.pageX - disx) > 0) && (e.pageX - disx > 0) ) {
						video_call.style.left = e.pageX - disx+'px';
					}
					if ( (document.documentElement.clientHeight - 610 - (e.pageY - disy) > 0) && (e.pageY - disy > 0) ) {
						video_call.style.top = e.pageY - disy+'px';
					}
				}
				document.onmouseup = function(){
					document.onmousemove = document.onmouseup = null;
				}
			}
		}
	}
}
</script>

<style scoped>
.facetime {
	font-family:"微软雅黑";
	width: 100%;height: 100%;
}
.video_call{
	width: 334px;
	height: 610px;
	position:fixed;top:0;left:0;z-index:999999;background:#000;
}
.get_video_call{
	background:#4caf50;

	width:55px;height:55px;position:absolute;bottom:100px;
	left:139.5px;border-radius:50%;
}

.close_video_call{
	background:#f45454;

	width:55px;height:55px;position:absolute;bottom:20px;
	left:139.5px;border-radius:50%;
}
.waiting{
	width: 334px;
	height: 610px;
	text-align:center; color:#FFF;
	padding-top:200px;
}
.local-stream {
	width: 100px;
	height: 150px;
	position:absolute;top:20px;
	right:20px;
}
/*视频播放圆角*/
/deep/ .vjs-tech{
	border-radius: 8px;
	overflow: hidden;
}
/deep/ .video-js{
	border-radius: 8px;overflow: hidden;font-size: 5px;
}
</style>
