<template>
	<div id="expertOrder" class="order">
		<div style="width: 1500px;margin: 90px auto 0;display: flex;justify-content: space-between;">
			<div class="pageLeft">
				<!-- 视频拨号-->
				<div class="plate" v-if="order.status == 3 && order.type == 1">
					<div class="work-title">
						<div class="work-icon"></div>
						<div>视频连线</div>
					</div>
					<div style="margin: 20px 50px;overflow: hidden;width: 100%;height: 360px;">
						<el-button type="primary" @click="call">拨号</el-button>
						<face-time></face-time>
					</div>
				</div>
				<!-- 订单基础信息-->
				<div class="plate">
					<div class="menu">
						<div class="work-title">
							<div class="work-icon"></div>
							<div>咨询订单详情</div>
						</div>
					</div>
					<div class="orderDetail">
						<div class="item">
							<span class="label">咨询话题</span>{{ order.topicTitle }}
						</div>
						<div class="item">
							<span class="label">咨询费用</span>{{ order.consultCost }}元
						</div>
						<div class="item">
							<span class="label">咨询方式</span>{{ consultType(order.type) }}
						</div>
					</div>
					<div class="orderDetail">
						<div class="item">
							<span class="label">委托方</span><span style="color:#57AAFF;">{{ order.userName }}</span>
						</div>
						<div class="item" v-if=" order.expertName!= null">
							<span class="label">咨询专家</span><span style="color:#57AAFF;">{{ order.expertName }}</span>
						</div>
						<div class="item">
							<span class="label">订单状态</span><span
							style="color:#8BC34A;">{{ orderStatus(order.status) }}</span>
						</div>
					</div>
					<div class="orderDetail">
						<div class="item">
							<span class="label">咨询标题</span>{{ order.title }}
						</div>
						<div class="item" v-if="order.typeName!= null">
							<span class="label">咨询类目</span><span style="color:#57AAFF;">{{ order.typeName }}</span>
						</div>
						<div class="item">
							<span class="label">咨询时长</span><span style="">{{ order.consultDuration }}小时</span>
						</div>
					</div>
					<div class="orderDetail">
						<div class="item">
							<span class="label">咨询日期</span><span>{{ order.consultTime | parseTime('{y}-{m}-{d} {h}:{m}') }}</span>
						</div>
					</div>
					<div style="display: flex;justify-content: space-between;margin: 0 40px;">
						<div style="flex: 1;padding: 26px 0;display: flex;justify-content: flex-start;line-height: 25px;">
							<div class="label">咨询内容</div>
							{{ order.content }}
						</div>
					</div>

				</div>
				<!-- 订单附件-->
				<div v-if="user.uuid === order.expert || user.uuid === order.uuid">
					<div class="plate" v-if="order.enclosure.length > 0">
						<div class="work-title">
							<div class="work-icon"></div>
							<div>咨询附件</div>
						</div>
						<div class="file" v-for="(item,index) in order.enclosure" :key="index" @click="download(item)">
							<img src="./imgs/file.png" style="width: 30px;height: auto;margin-right: 12px;">{{ item.name }}
						</div>
						<!--					<div class="btn">+ 上传附件</div>-->
					</div>
				</div>
				<!-- 专家函复-->
				<div class="plate" v-if="order.status === 3 && user.uuid === order.expert && (order.consultTime < new Date().getTime())">
					<div class="work-title">
						<div class="work-icon"></div>
						<div>专家复函</div>
					</div>
					<div class="reply" style="margin: 20px 50px;overflow: hidden;">
						<el-input v-model="order.reply.title" placeholder="请输入标题"></el-input>
						<TinymceEditor :innerDrawer="innerDrawer =true" :value="order.reply.content" :tinymce_height="'500'" ref="ch"></TinymceEditor>
						<div @click="replyOrder" class="hand"
						     style="width: 132px;height: 40px;background: #2970FF;border-radius: 5px;color: #ffffff;line-height: 40px;text-align: center;float: right;margin-top: 20px; ">
							确认回复
						</div>
						<div @click="preservation" class="hand"
						     style="width: 132px;height: 40px;background: #2970FF;border-radius: 5px;color: #ffffff;line-height: 40px;text-align: center;float: right;margin-top: 20px;margin-right: 20px; ">
							保存回复
						</div>
					</div>
				</div>

				<!-- 判断是否是专家，如果是专家，显示专家复函，如果是委托方，显示委托方复函-->
				<div class="plate" v-if="order.status >= 4 &&  order.status < 7">
					<div class="work-title">
						<div class="work-icon"></div>
						<div>专家复函</div>
					</div>
					<div class="reply" style="overflow: hidden;">
						<div style="padding:20px 40px">
							<el-tabs type="border-card">
								<el-tab-pane :label="order.reply.updateTime.slice(0, 10)">
									<div style="padding:10px 40px">
										<div style="text-align: center; font-size: 18px; font-weight: 600; margin-bottom: 20px">
											{{ order.reply.title }}
										</div>
										<div class="html-content" v-html="order.reply.content"></div>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
						<div v-if="user.uuid === order.uuid">
							<div @click="confirmOrder" class="hand op" v-if="order.status == 4"
							     style="width: 132px;height: 40px;background: #2970FF;border-radius: 5px;color: #ffffff;line-height: 40px;text-align: center;float: right;margin-top: 20px; margin-right: 40px">
								成果确认
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="pageRight">
				<div class="plate">
					<div class="work-title">
						<div class="work-icon"></div>
						<div>订单动态</div>
					</div>
					<div class="dynamic">
						<order-log :logList="this.logList" :orderInfo = "this.order" @childEvent="refresh()"></order-log>
					</div>
				</div>

				<div class="plate" v-if="user.uuid==order.expert || user.uuid==order.uuid">
					<div style="min-height: 220px">
						<div class="work-title">
							<div class="work-icon"></div>
							<div>留言板</div>
						</div>
						<div v-if="order.status < 6" style="padding: 20px">
							<div>
								<el-input
									type="textarea"
									resize="none"
									:rows="5"
									placeholder="给委托方留言"
									v-model="messageContent"
									:autosize="{ minRows: 5, maxRows: 10 }"
									:show-word-limit="true"
									maxlength="300">
								</el-input>
								<div style="display: flex; justify-content: right; margin: 12px 0">
									<el-button class="hand op"
									           style="height: 30px; line-height: 2px; background-color: #2970FF; color: #FFFFFF"
									           @click="upMessage">发表
									</el-button>
								</div>
							</div>
						</div>
                        <div v-if="messageListShow.length > 0">
                            <div v-for="(item, index) in messageListShow" :key="index" style="margin: 20px">
                                <span style="color: #1C7EFF">{{ item.user.nickName }}：</span>
                                <span>{{ item.content }}</span>
                                <div style="font-size: 14px; color: #999999; margin: 6px 0 12px 0">{{ item.creationTime | formatTime('{y}-{m}-{d}') }}</div>
                                <hr style="margin-bottom: 20px; border-top:1px solid #999999"/>
                            </div>
                        </div>

					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import FaceTime from "../../components/common/FaceTime";
import TinymceEditor from "@/components/common/Editor";
import {formatTime, parseTime} from '@/utils/utils'
import orderLog from "./components/orderLog.vue";

export default {
	name: "",
	components: {
		orderLog,
		TinymceEditor,
		FaceTime,
	},
	computed: {
		user() {
			return this.$store.state.user
		},
	},
	filters: {
		parseTime, formatTime
	},
	data() {
		return {
			order: {
				title: null,
				price: null,
				expert: null,
				userName: null,
				status: null,
				enclosure: []
			},
			//订单号
			orderNo: "",
			//预支付订单号
			payOrderNo: "",
			//视频所有人的uuid集合
			ids: [],

			messageContent: '',
			messageListShow: [],
			showAppraise: false,

			logList: [],

		}
	},
	props: {},
	created: function () {
        var that = this;
        //监听订单动态
        this.$EventBus.$EventBus.$off("notice");
        this.$EventBus.$on("notice", (data) => {
            this.$nextTick(() => {
                if(data.info.type == 9){
                    that.refresh();
                }
            });
        });

	},
	mounted: function () {
		var that = this;
		this.backTop();
		this.orderNo = this.$route.params.orderNo;
		this.getExpertOrderInfo();
		this.getMessage();
		this.getLogs();

		//预支付订单监听
		this.$EventBus.$EventBus.$off("prePayOrder");
		this.$EventBus.$on("prePayOrder", (data) => {
			this.$nextTick(() => {
				if (data.code == 100) {
					that.bankPay(data.toPay);
				}
			});
		});

		//支付成功监听
		this.$EventBus.$EventBus.$off("payMsg");
		this.$EventBus.$on("payMsg", (data) => {
			this.$nextTick(() => {
				if (data.code == 100) {
                    that.refresh();
				}
			});
		});
	},
	methods: {
        //刷新数据
        refresh(){
            this.getLogs();
            this.getExpertOrderInfo();
            this.getMessage();
        },

		//银行卡确认支付
		bankPay(data) {
			var that = this;
			var payParams = {};
			payParams.orderNo = data.orderNo;
			payParams.code = data.code;
			payParams.payPwd = data.payPwd;
			//请求汇付接口
			this.newApi.settleAccountPennyDetermine(payParams).then(() => {})
		},

		// 获取日志
		getLogs() {
			this.newApi.getExpertOrderLogs({orderNo: this.orderNo}).then(res => {
				this.logList = res.data
			})
		},

		/**
		 * 获取订单留言
		 */
		getMessage() {
			this.newApi.getOrderMessage({orderNo: this.orderNo}).then(res => {
				this.messageListShow = res.data
			})
		},

		// 发表留言
		upMessage() {
			this.newApi.leaveMessage({content: this.messageContent, orderNo: this.orderNo, type: 1}).then(res => {
				if (res.isSuccess === 1) {
					// this.utils.sus(res.data);
					this.messageContent = '';
					this.getMessage()
				}
			})
		},

		// 关闭弹窗
		closeEvent() {
			this.showAppraise = false
		},

		call() {
			if (this.order.consultTime && this.order.consultTime > new Date().getTime()) {
				this.utils.err("未到订单咨询时间，无法开始！");
				return false;
			}
			if (this.order.consultTime && (this.order.consultTime + (this.order.consultDuration * 3600) * 1000) < new Date().getTime()) {
				this.utils.err("订单已过期！");
				return false;
			}
			if (this.user.uuid === this.order.uuid) {
				this.videoCall(this.order.expert);
			}
			if (this.user.uuid === this.order.expert) {
				this.videoCall(this.order.uuid);
			}
		},

		backTop() {
			document.documentElement.scrollTop = document.body.scrollTop = 0;
		},
		consultType(type) {
			if (type == 1) {
				return "视频咨询"
			} else if (type == 2) {
				return "在线函复"
			} else if (type == 3) {
				return "现场会议"
			}
		},

		orderStatus(status) {
			if (status == 1) {
				return "待专家接单"
			} else if (status == 0) {
				return '已拒绝'
			} else if (status == 2) {
				return "待托管费用"
			} else if (status == 3) {
				return "进行中"
			} else if (status == 4) {
				return "待确认"
			} else if (status == 5) {
				return "待评价"
			} else if (status == 6) {
				return "已完成"
			} else if (status == 7) {
				return "退款申请中"
			} else if (status == 8) {
				return "退款失败"
			} else if (status == 9) {
                return "退款完成"
            }
		},

		// 获取订单信息
		getExpertOrderInfo() {
			var that = this;
			this.newApi.getExpertOrderInfo({
				orderNo: that.orderNo
			}).then(res => {
				that.order = res.data;
				that.payOrderNo = res.data.payOrderNo;
			})
		},

		// 确认成果
		confirmOrder() {
			var that = this;
            that.utils.confirm("成果确认后咨询费用将直接打款给专家，请确认？", function () {
                that.newApi.confirmExpertOrder({
                    orderNo: that.orderNo,
                }).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                    }
                })
            })

		},
		//保存成果
		preservation(){
			var that = this;
			let chil = that.$refs.ch;
			const data={
				content:chil.get_content(),
				orderNo:that.order.orderNo,
				title:that.order.reply.title,
			}
			that.newApi.preservationReply(data).then(res => {
				if(res.isSuccess === 1){
					that.utils.sus(res.data);
				}
			})
		},

		//上传成果
		replyOrder() {
			var that = this;
			let chil = that.$refs.ch;
			let content = chil.get_content();
			this.newApi.replyExpertOrder({
				orderNo: that.orderNo,
				title: that.order.reply.title,
				content: content
			}).then(res => {
				if (res.isSuccess === 1) {
					that.utils.sus(res.data);
					that.getExpertOrderInfo();
					this.getLogs()
				}
			})
		},



		download(item) {
			this.utils.download(item.url, item.name);
		},

	}
}

</script>

<style>
.order {
	min-height: 100vh;
}

#expertOrder .pageLeft {
	width: 1136px;

}

#expertOrder .plate {
	background: #FFFFFF;
	box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
	border-radius: 5px 5px 5px 5px;
	padding: 20px 0px;
	margin-top: 10px;
	overflow: hidden;
}

#expertOrder .menu {
	font-size: 22px;
	color: #444444;
	display: flex;
	justify-content: space-between;
}

#expertOrder .menu .menuTitle {
	display: flex;
	justify-content: flex-start;
}

#expertOrder .pageLeft .plate .orderDetail {
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	margin: 0 40px;
	border-bottom: 1px solid #E1E1E1;
	font-size: 16px;
}

#expertOrder .pageLeft .plate .orderDetail .item {
	width: 300px;
	padding: 26px 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

#expertOrder .pageRight {
	width: 354px;
}

#expertOrder .label {
	color: #888888;
	margin-right: 30px;
	width: 64px;
	flex: 0 0 auto;
}

#expertOrder .separate {
	width: 10px;
	height: 26px;
	background: #FF9800;
	border-radius: 0px 0px 0px 0px;
	margin-right: 16px;
}

#expertOrder .plate .file {
	height: 65px;
	margin: 20px 50px 0;
	background-color: #EBEBEB;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 14px 18px;
	box-sizing: border-box;
}

#expertOrder .btn {
	width: 134px;
	height: 43px;
	border-radius: 5px 5px 5px 5px;
	border: 1px solid #FF9900;
	color: #FF9900;
	font-size: 16px;
	line-height: 43px;
	text-align: center;
	margin: 20px auto 0;
}

#expertOrder .reply .el-input__inner {
	border-radius: 0px;
	border-color: #ccc;
	border-bottom: none;
	height: 50px;
	line-height: 50px;
	font-size: 22px;
}

.plate .dynamic {
	margin: 20px 26px;
}

.plate .dynamic .node {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: #031F88;
}

.plate .dynamic .node .no {
	width: 24px;
	height: 24px;
	border: 1px solid #031F88;
	box-sizing: border-box;
	border-radius: 50%;
	text-align: center;
	font-size: 12px;
	line-height: 22px;
	margin-right: 16px;
}

.plate .dynamic .node .title {
	font-size: 16px;
	font-weight: 600;
}

.plate .dynamic .nodeItem {
	margin-left: 12px;
	border-left: 1px solid #031F88;
	padding: 10px 0 5px;
	line-height: 30px;
	padding-left: 27px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.plate .dynamic .nodeItem .bt {
	padding: 4px 10px;
	background-color: #FF0000;
	font-size: 12px;
	color: #ffffff;
	border-radius: 5px;
	margin: 0 8px;
}

.circle {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background-color: #031F88;
	margin-right: 10px;
	flex: 0 0 auto;
	margin-top: 9px;
}

.order .work-title {
	display: flex;
	line-height: 20px;
	font-size: 18px;
	font-weight: 600;
	color: #031F88;
}

.order .work-title .work-icon {
	width: 6px;
	height: 20px;
	background-color: #031F88;
	margin-right: 14px;
}

.log {
	padding: 14px 30px 14px 20px;
	margin-top: 12px;
	border: 1px solid #D9D9D9;
	border-radius: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.log .ico {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: 14px;
}

.html-content >>> img {
	max-width: 100%;
}

.order .event-form .top {
	text-align: center;
	position: relative;
	font-size: 20px;
	font-weight: 600;
	color: #444444;
	border: 1px solid #CECFD0;
	height: 60px;
	box-sizing: border-box;
	line-height: 60px;
}

.order .event-form .title-form {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 12px;
}

.order .event-form .foot-btn {
	width: 128px;
	height: 20px;
	font-size: 16px;
	border-radius: 5px;
	text-align: center;
	padding: 10px;
}

.order .event-form .el-dialog__header {
	padding: 0;
}

.order .event-form .el-dialog__body {
	padding: 0;
}
</style>
