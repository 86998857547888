<template>
	<div style="width: 98%;height: auto;overflow: hidden;padding: 0 1%">
		<el-timeline>
			<el-timeline-item type="success" icon="el-icon-loading" size="large" v-if="(user.uuid==orderInfo.expert || user.uuid==orderInfo.uuid) && orderInfo.status!=6 && orderInfo.status!=8">
				<el-card>
					<label v-if="orderInfo.status===0">
                        <h4>订单结束</h4>
					</label>
					<label v-if="orderInfo.status===1">
						<label v-if="orderInfo.expert===user.uuid">
							<h4>是否接受订单：<br></h4>
							<el-button type="primary" plain size="mini" round  @click="OrderReceiving">接受订单</el-button>
							<el-button type="danger" plain size="mini" round @click="OrderRefuse">拒绝接单</el-button>
						</label>
						<label v-else>
							<h4>等待专家接受订单中...</h4>
						</label>
					</label>
					<label v-if="orderInfo.status===2">
						<label v-if="orderInfo.expert===user.uuid">
							<h4>等待委托方支付费用中...</h4>
						</label>
						<label v-else>
							<h4>专家已接单，请托管费用</h4>
							<el-button type="primary" plain size="mini" round @click="toPayMoney" style="margin: 10px 0px;">支付费用</el-button>
						</label>
					</label>
					<label v-if="orderInfo.status===3">
						<label v-if="orderInfo.expert===user.uuid">
							<h4>订单进行中...</h4>
						</label>
						<label v-else>
							<h4>专家完成订单中...<br></h4>
							<label v-if="orderInfo.consultTime > new Date().getTime()">
								<span>(未开始咨询前允许申请退款)</span><el-button type="danger" plain size="mini" round @click="applyRefund" style="margin: 10px 0px;">申请退款</el-button>
							</label>
							<label v-else>
								<span>已到咨询时间禁止申请退款</span>
							</label>
						</label>
					</label>
					<label v-if="orderInfo.status===4">
						<label v-if="orderInfo.expert===user.uuid">
							<h4>等待委托方确认...</h4>
						</label>
						<label v-else>
							<h4>专家已上传成果，请确认成果</h4>
						</label>
					</label>
					<label v-if="orderInfo.status===5">
						<label v-if="orderInfo.expert===user.uuid">
							<h4>委托方已确认成果,等待评价<br></h4>
						</label>
						<label v-else>
							<h4>请输入订单评价<br></h4>
							<el-rate v-model="score" color="#FF0000"></el-rate>
							<el-input
								type="textarea"
								:rows="2"
								placeholder="请输入评价内容"
								v-model="evaluateContent">
							</el-input>
							<el-button type="info" plain size="mini" round @click="addAppraise" style="margin: 10px 0px;">确认评价</el-button>
						</label>
					</label>
					<label v-if="orderInfo.status === 6">
						<h4>订单完成</h4>
					</label>
                    <label v-if="orderInfo.status === 9">
                        <h4>订单结束</h4>
					</label>
				</el-card>
			</el-timeline-item>
			<el-timeline-item icon="el-icon-success" color="blue"  :timestamp="log.creationTime" placement="top" v-for="(log,index) in logList" :key="index">
				<el-card>
                    <h4>{{log.user.nickName}}:{{ log.content }}</h4>
                    <div style="font-size: 12px;margin-top: 5px;" v-if="log.type == 111">
                        拒绝理由：{{ orderInfo.refuseReason }}
                    </div>
                    <div style="font-size: 12px;margin-top: 5px;" v-if="log.type == 107">
                        退款理由：{{ orderInfo.refundReason }}
					</div>
                    <div style="font-size: 12px;margin-top: 5px;" v-if="log.type == 108">
                        {{ orderInfo.refundReason }}
                    </div>
				</el-card>
			</el-timeline-item>
		</el-timeline>
	</div>
</template>
<script>
export default {
	name: 'orderLog',
	components: {},
	computed: {
		user() {
			return this.$store.state.user
		},
		is_login() {
			return this.$store.state.is_login
		}
	},
	props: {
		logList: {
			  type: Array,
		},
		orderInfo: {
			type: Object,
		}
	},
	data(){
		return {
			evaluateContent:'',
			score: 0,
			refundContent:'',
		}
	
	},
    mounted() {
    },
    methods:{
		//申请退款
		applyRefund() {
			var that = this;
			this.$prompt('请输入申请退款理由', '申请退款', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputPattern: /^[\s|\S]{10,}$/,
				inputErrorMessage: '理由少于10个字符'
			}).then(({ value }) => {
                that.openLoading("退款申请中....");
				that.newApi.applyRefund({
					orderNo: that.orderInfo.orderNo,
					refundReason:value
				}).then(res => {
					if (res.isSuccess === 1) {
						that.utils.sus(res.data);
                        that.$emit('childEvent');
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '取消'
				});
			});
		},

		//接单
		OrderReceiving() {
			var that = this;
            that.utils.confirm("确定接单?", function () {
                that.newApi.expertOrderReceiving({
                    orderNo: that.orderInfo.orderNo
                }).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                        that.$emit('childEvent');
                    }
                })
            })

		},
		
		// 拒单
		OrderRefuse() {
			var that = this;
			this.$prompt('请输入拒绝理由', '拒绝订单', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputPattern: /^[\s|\S]{5,}$/,
				inputErrorMessage: '理由少于5个字符'
			}).then(({ value }) => {
				that.utils.confirm("拒绝订单不可撤回，确定拒绝?", function () {
					that.newApi.refuseExpertOrder({
						orderNo: that.orderInfo.orderNo,
						refuseReason:value
					}).then(res => {
						if (res.isSuccess === 1) {
							that.utils.sus(res.data);
                            that.$emit('childEvent');
						}
					})
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '取消'
				});
			});
		},

		// 确认评价
		addAppraise() {
            var that = this;
            that.utils.confirm("评价完无法撤回，继续发表评价？",function (){
                that.newApi.evaluateExpertOrder({
                    content: that.evaluateContent,
                    score: that.score,
                    orderNo: that.orderInfo.orderNo,
                    topicId: that.orderInfo.expertTopic,
                    expert: that.orderInfo.expert,
                }).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                        that.$emit('childEvent');
                    }
                })
            })

		},

		toPayMoney() {
			//   <AaDaPayCode v-if="newOrderNo" type="3" :collectionUuid="order.expert" :payAmt="order.consultCost+''"
			//   payType="6" :orderNo="newOrderNo" @change="changeOrder($event)" ref="child"></AaDaPayCode>
			var that = this;
            that.utils.confirm("确认支付咨询费" + this.orderInfo.consultCost + "元?", function () {
                let params = that.$store.state.payParams;
                params.type = 3;
                params.collectionUuid = that.orderInfo.expert;
                params.payAmt = that.utils.keepTwoDecimalFull(that.orderInfo.consultCost);
                params.payType = 6;
                params.orderNo = that.orderInfo.payOrderNo;
                that.sign = that.utils.random_string(6);
                that.newApi.addAndSavePrePayOrder({type: 1, payParams: JSON.stringify(params), sign: that.sign}).then(() => {
                    that.b('/newAdaPay/?sign=' + that.sign);
                })
            })

		},
	}
}

</script>


<style scoped>

</style>
